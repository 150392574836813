<template lang="pug">
  .side-menu-wrapper
    .side-menu
      .top-part
        template(v-for="(value, index) in menu")
          button.menu-item(:key="index" :class="{ active: currentRoute == value.route }" @click="goTo(value.route)" :disabled="value.disabled")
            .icon-wrapper
              EIcon(:name="value.icon" width="16px" height="16px" :color="currentRoute == value.route ? '#28A745' : value.disabled ? '#c9cfd5' : '#868E96'")
            p {{ value.name }}
      .bottom-part
        button.menu-item(v-if="userAuth.company" @click="openBalanceModal")
          .icon-wrapper
            EIcon(name="ruble" width="16px" height="16px" color="#868E96")
          p Баланс: {{ balance }}р.
        template(v-for="(value, index) in bottomMenu")
          button.menu-item(:key="index" @click="value.action" :disabled="value.disabled")
            .icon-wrapper
              EIcon(:name="value.icon" width="16px" height="16px" :color="value.disabled ? '#c9cfd5' : '#868E96'")
            p {{ value.name }}
</template>

<script>
import { StoreActionTypes } from "@/store/types";
export default {
  computed: {
    currentRoute() {
      return this.$route.path;
    },
    balance() {
      return this.$store.getters.getBalance ?? 0;
    },
    userAuth() {
      return this.$store.getters.getAuthentificationProfile;
    },
  },
  async mounted() {
    await this.$store.dispatch(StoreActionTypes.GET_BALANCE);
    if (!window.localStorage.getItem("ewaTutorialChecked")) {
      this.openIntroduction();
    }
  },
  data() {
    return {
      menu: [
        {
          name: "Каналы",
          icon: "phone-outline",
          route: "/ewa",
        },
        {
          name: "Диалоги",
          icon: "messages-outline",
          route: "/ewa/dialogues",
          disabled: true,
        },
        {
          name: "Пользователи",
          icon: "users-outline",
          route: "/ewa/users",
          disabled: true,
        },
        {
          name: "Общие настройки",
          icon: "cog-outline",
          route: "/ewa/settings",
          disabled: true,
        },
      ],
      bottomMenu: [
        {
          name: "Видеоподсказка",
          icon: "help-circle-bold",
          action: this.showTutorialVideo,
          disabled: false,
        },
        {
          name: "FAQ",
          icon: "life-ring",
          action: this.openIntroduction,
          disabled: false,
        },
        {
          name: "Выход",
          icon: "sign-out",
          action: this.handleLogout,
        },
      ],
    };
  },
  methods: {
    goTo(route) {
      if (this.currentRoute == route) {
        return;
      }

      this.$router.push(route);
    },

    handleLogout() {
      this.$store.dispatch(StoreActionTypes.LOG_OUT);
      this.$router.push("/login");
    },

    showTutorialVideo() {
      this.$store.dispatch(
        StoreActionTypes.SET_AUTHENTIFICATION_SHOW_TUTORIAL_VIDEO,
        true
      );
    },
    openFAQ() {
      window.open("https://offkoenig.ru", "_blank");
    },

    openIntroduction() {
      this.$root.openDialog({
        component: {
          is: () => import("@/components/ewa/modals/Introduction.vue"),
          _name: "Introduction",
        },
        settings: {
          position: "center",
        },
      });
    },

    openBalanceModal() {
      this.$root.openDialog({
        component: {
          is: () => import("@/components/ewa/modals/Payment.vue"),
          _name: "Payment",
        },
        settings: {
          position: "center",
        },
      });
    },
  },
};
</script>

<style lang="scss">
.side-menu-wrapper {
  height: 100%;
  .side-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 208px;
    height: 100%;
    background: #f8f9fa;
    padding: 24px 14px;

    .menu-item {
      height: 36px;
      width: 100%;
      display: flex;
      align-items: center;

      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #868e96;
      margin-bottom: 2px;

      &:hover {
        color: $green;

        .icon {
          color: $green !important;
        }
      }

      &:disabled {
        color: #c9cfd5;
        &:hover {
          background: #f8f9fa;
          color: #c9cfd5;

          .icon {
            color: #c9cfd5 !important;
          }
        }
        cursor: not-allowed;
      }

      p {
        margin-bottom: 0;
      }

      &:hover {
        background: #fff;
      }

      &.active {
        font-style: normal;
        font-weight: 700;
        color: $green;
        background: #fff;
      }

      .icon-wrapper {
        display: flex;
        align-items: center;
        padding: 10px 8px;
      }
    }
  }
}
</style>
