<template lang="pug">
  .dialog-container
    transition(name='fade')
      .backdrop.cursor-pointer(v-if='dialogQueue.length > 0' :style='`background: rgba(0, 0, 0, 0.15);`' @click='closeDialog()' tabindex='0')
    transition(:name='dialog ? dialog.settings.position : "scaling"')
      .dialog(v-if='dialog' :class='dialog.settings.position')
        .uniqcontent.relative
          v-component(v-bind='dialog.component')
</template>

<script>
import { StoreMutationTypes } from "@/store/types";
export default {
  name: "CModal",
  mounted() {
    window.openModal = (modalName, opts = {}, force = false) => {
      this.openDialog(
        {
          component: {
            is: () => import(`../modals/${modalName}.vue`),
            _name: modalName,
            ...opts,
          },
        },
        force
      );
    };

    window.hideModal = (...args) => this.closeDialog(...args);

    window.addEventListener("keyup", (ev) => {
      if (ev.which === 27) {
        this.$store.commit(StoreMutationTypes.SET_DIALOG_CLOSE, {
          clear: true,
        });
      }
    });

    window.modals = {
      showUser: (_id) => window.openModal("Profile", { _id }),
    };

    this.$root.openDialog = (...args) => this.openDialog(...args);
    this.$root.closeDialog = (...args) => this.closeDialog(...args);
  },
  watch: {
    dialogQueue: {
      deep: false,
      handler(newVal) {
        if (newVal.length > 0) {
          //document.getElementsByTagName('body')[0].style.overflow = 'hidden';
        } else {
          document.getElementsByTagName("body")[0].style.overflow = "auto";
        }
        this.reopenDelay = true;

        setTimeout(() => {
          this.reopenDelay = false;
        }, 150);
      },
    },
    isDesktop() {
      this.$store.commit(StoreMutationTypes.SET_DIALOG_CLOSE, {
        clear: true,
      });
    },
  },
  computed: {
    dialogQueue() {
      return this.$store.getters.getDialogQueue;
    },
  },
  asyncComputed: {
    dialog() {
      if (this.reopenDelay) {
        return null;
      }

      if (this.dialogQueue[0]) {
        this.dialogQueue[0].component.is().then((v) => {
          if (window.location.href.includes("auth")) {
            return;
          }

          const modal = v.default.name;
          if (!modal) {
            return;
          }

          const obj = {
            ...this.dialogQueue[0].component,

            modal,
          };

          Object.entries(obj).forEach(async ([key, value]) => {
            if (typeof value == "object") {
              obj[key] = JSON.stringify(value);
            }
          });

          delete obj.is;

          try {
            if (
              JSON.stringify(this.$route.query) != JSON.stringify(obj) &&
              this.isDesktop
            )
              this.$router.replace({ query: { ...obj } });
          } catch {
            //console.error('error')
          }
        });
      } else {
        //console.error('error')
      }

      return this.dialogQueue[0];
    },
  },
  data() {
    return {
      reopenDelay: false,
    };
  },
  methods: {
    openDialog(obj, force = false, position = "center") {
      this.$store.commit(StoreMutationTypes.SET_DIALOG_QUEUE, {
        dialog: Object.assign(
          {
            settings: {
              backdrop: "#00000060",
              position,
            },
          },
          obj
        ),
        force: force,
      });
    },

    internalCloseDialog(clear = false) {
      this.$store.commit(StoreMutationTypes.SET_DIALOG_CLOSE, {
        clear: clear,
      });

      if (Object.keys(this.$route.query).length > 0 && this.isDesktop) {
        this.$router.replace({ query: {} });
      }
    },

    closeDialog() {
      this.reopenDelay = true;

      this.internalCloseDialog();

      setTimeout(() => {
        this.reopenDelay = false;
      }, 150);
    },
  },
};
</script>

<style lang="scss">
.dialog-container {
  position: absolute;
  max-width: 100vw;
  max-height: 100vh;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  .backdrop {
    position: fixed;
    pointer-events: all;
    width: 100%;
    height: 100%;
    z-index: 998;
  }

  .dialog {
    position: fixed;
    z-index: 999;
    pointer-events: none;

    &.center {
      width: 100%;
      height: 100%;
    }

    &.bottom,
    &.top {
      width: 100%;
    }

    &.right {
      right: 0;
      height: 100%;
      width: 640px;

      .uniqcontent {
        top: 0;
        right: 0;
        left: unset;
        transform: unset;
      }
    }

    .uniqcontent {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      pointer-events: all;
    }
  }
}

.scaling-leave-active,
.center-leave-active {
  animation: scale-out 0.25s cubic-bezier(0.71, 0.16, 0, 1.04) both;
}
.scaling-enter-active,
.center-enter-active {
  animation: scale-in 0.25s cubic-bezier(0.36, 0.01, 0, 1.32) both;
}

@keyframes scale-in {
  0% {
    transform: scale(0);
    -webkit-transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 1;
  }
}

@keyframes scale-out {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }
}

.fade-leave-active {
  animation: fade-out 0.25s cubic-bezier(0.71, 0.16, 0, 1.04) both;
}
.fade-enter-active {
  animation: fade-in 0.25s cubic-bezier(0.36, 0.01, 0, 1.32) both;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    pointer-events: none;
    opacity: 1;
  }
  100% {
    pointer-events: none;
    opacity: 0;
  }
}

.right-leave-active {
  animation: slide-right-out 0.15s linear both;
}
.right-enter-active {
  animation: slide-right 0.15s linear both;
}

@keyframes slide-right {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slide-right-out {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}
</style>
