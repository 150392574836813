
import Vue from "vue";
import Component from "vue-class-component";

import Row from "./Row.vue";
import Header from "./Header.vue";

@Component({
  components: {
    Row,
    Header,
  },
})
export default class EwaTable extends Vue {
  get instanceList() {
    return this.$store.getters.getInstanceList;
  }
  get dataIsLoading() {
    return this.$store.getters.ewaDataIsLoading;
  }
}
