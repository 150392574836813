<template lang="pug">
  .icon(:style="`width: ${width}; height: ${height}; color: ${color}; ${iconStyle}`" v-html="require(`!html-loader!@/assets/images/svg/${name}.svg`)")
</template>

<script>
export default {
  name: "SVGIcon",
  props: {
    name: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: "30px",
    },
    height: {
      type: String,
      default: "30px",
    },
    color: {
      type: String,
      default: "white",
    },
    iconStyle: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.icon {
  svg {
    width: 100%;
    height: 100%;
    vertical-align: unset;

    path {
      width: 100%;
    }
  }
}
</style>
