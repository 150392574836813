
import Component from "vue-class-component";
import { EmptyDataMessageProps } from "./IEmptyDataMessage";
import ActionButton from "@/components/lib/button/ActionButton.vue";

@Component({
  components: {
    ActionButton,
  },
})
export default class EmptyDataMessage extends EmptyDataMessageProps {
  private componentClass = "EmptyDataMessage";

  get hasAction(): boolean {
    return !!this.actionText;
  }
}
