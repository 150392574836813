<template lang="pug">
  .search-input-wrapper
    .search-input
      EIcon.icon(name="search" width="16px" height="16px" color="#868E96")
      input(placeholder="Поиск и фильтр")
</template>

<script>
export default {};
</script>

<style lang="scss">
.search-input-wrapper {
  .search-input {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 596px;
    height: 40px;
    background: #f8f9fa;
    border-radius: 4px;
    padding: 12px 8px;

    .icon {
      color: #868e96;
      margin-right: 8px;
    }

    input {
      width: 100%;
      font-size: 14px;
      line-height: 16px;

      &::placeholder {
        color: #868e96;
      }
    }
  }
}
</style>
