import Vue from "vue";
import { PropType } from "vue";

export const EmptyDataMessageProps = Vue.extend({
  props: {
    message: {
      required: true,
      type: String,
    },
    actionText: {
      required: false,
      type: String,
    },
    actionListener: {
      required: false,
      type: Function as PropType<() => void>,
    },
  },
});
