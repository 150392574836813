
import Component from "vue-class-component";
import { ICallReviewer } from "./ICallReviewer";
import { IconDefinition, faUser } from "@fortawesome/free-solid-svg-icons";
import { getRatingColor } from "@/services/reports/reportsUtils";

@Component({
  methods: { getRatingColor },
})
export default class CallReviewer extends ICallReviewer {
  private componentClass = "CallReviewer";
  private edit: IconDefinition = faUser;

  get activeEstimationId() {
    return this.$store.getters.getSelectedCallEstimatedCheckList
      ? this.$store.getters.getSelectedCallEstimatedCheckList.id
      : null;
  }

  get getActiveClass() {
    if (this.isEdit && !this.activeEstimationId) {
      return "active";
    }

    if (!this.isEdit && this.activeEstimationId == this.reviewer.id) {
      return "active";
    }

    return "";
  }

  get reviewerWithoutSurname() {
    const splittedName = this.reviewer.auditor.name.split(" ");
    if (
      splittedName[0].toLowerCase() == "фамилия" ||
      splittedName[0].toLowerCase().includes("ф.")
    ) {
      return splittedName[1];
    }
    return this.reviewer.auditor.name;
  }
}
