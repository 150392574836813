import { CallEstimationModel } from "@/services/types";
import Vue, { PropType } from "vue";

export const ICallReviewer = Vue.extend({
  props: {
    isEdit: {
      required: true,
      type: Boolean,
    },
    reviewer: {
      required: false,
      type: Object as PropType<CallEstimationModel>,
    },
    onClick: {
      required: false,
      type: Function as PropType<(id: number) => void>,
    },
  },
});
