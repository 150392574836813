<template lang="pug">
  .status
    .flag(:class="{ inactive: !active }")
    p {{ active ? 'активен' : 'не активен' }}
</template>

<script>
export default {
  name: "Common.Status",
  props: {
    active: {
      required: true,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.status {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 62px;

  .flag {
    width: 6px;
    height: 6px;
    background: #28a745;
    border-radius: 50%;
    margin-right: 4px;

    &.inactive {
      background: #a72828;
    }
  }

  p {
    font-size: 10px;
    line-height: 16px;
    color: #868e96;
    margin-bottom: 0;
  }
}
</style>
