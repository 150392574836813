<template lang="pug">
  button.e-button(@click="action")
    slot
</template>

<script>
export default {
  props: {
    action: {
      type: Function,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss">
.e-button {
}
</style>
