
import Vue, { PropType } from "vue";
import Component from "vue-class-component";
import {
  faTimesCircle,
  IconDefinition,
} from "@fortawesome/free-regular-svg-icons";

const ICallDetailsRatingHeader = Vue.extend({
  props: {
    closeListener: {
      required: true,
      type: Function as PropType<() => void>,
    },
  },
});

@Component
export default class CallDetailsRatingHeader extends ICallDetailsRatingHeader {
  private componentClass = "CallDetailsRatingHeader";
  private modalCloseIcon: IconDefinition = faTimesCircle;
}
