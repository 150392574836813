
import { CallCriterionsGroupedByCategoryModel } from "@/services/types";
import Vue from "vue";
import Component from "vue-class-component";
import ListAnswerSwitcherWrapper from "@/components/lib/switcher/list/ListAnswerSwitcherWrapper.vue";
import CollapseArea from "@/components/lib/collapse/CollapseArea.vue";
import ActionButton from "@/components/lib/button/ActionButton.vue";
import * as utils from "@/services/utils";

@Component({
  components: {
    ListAnswerSwitcherWrapper,
    CollapseArea,
    ActionButton,
  },
})
export default class CallRating extends Vue {
  public componentClass = "CallRating";

  private allCriterionsChecked = false;

  get selectedCall() {
    return this.$store.getters.getSelectedCall;
  }

  sortUnNuller = (value, projectId) => {
    if (!value || value[projectId] === undefined || value[projectId] == null) {
      return Infinity;
    }

    return value[projectId];
  };

  get categoriesList(): CallCriterionsGroupedByCategoryModel[] {
    return this.$store.getters.getCriterionsList
      .map((v) => {
        const selected = this.selectedCallCategories.find((t) => t.id == v.id);
        if (selected) {
          selected.position = v.position;
          selected.criterions = selected.criterions
            .map((criterion) => {
              const c = v.criterions.find((e) => e.id == criterion.id);
              if (c) {
                return {
                  position: c.position,
                  notes: c.notes,
                  options: criterion.options,
                  selectedOptionId: criterion.selectedOptionId,
                  ...criterion,
                };
              }
            })
            .filter((e) => e)
            .sort((a, b) => {
              return (
                this.sortUnNuller(a.position, v.projectId) -
                this.sortUnNuller(b.position, v.projectId)
              );
            });
        }
        return selected;
      })
      .filter((v) => v);
  }

  get selectedCallCategories(): CallCriterionsGroupedByCategoryModel[] {
    return this.$store.getters.getSelectedCallCategories;
  }

  get hasEstimatedCheckList(): boolean {
    return (
      this.$store.getters.getSelectedCallEstimatedCheckList &&
      this.$store.getters.getSelectedCallEstimatedCheckList.id
    );
  }

  get getRatingDate(): string {
    let date = "";
    if (this.hasEstimatedCheckList) {
      date = utils.renderDateFromString(
        this.$store.getters.getSelectedCallEstimatedCheckList.createdAt
      );
    }

    return date;
  }
}
