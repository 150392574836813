
import Component from "vue-class-component";
import Vue from "vue";
import { StoreActionTypes } from "@/store/types";
import DropdownItemsList from "@/components/ui/DropdownItemsList.vue";
import { ListItemModel } from "@/components/ui/types";
import InlineItemsList from "@/components/ui/InlineItemsList.vue";

const AddToProjectProps = Vue.extend({
  props: {
    checkListId: {
      type: String,
      required: true,
      validator: function (value: string) {
        return Number.isInteger(parseInt(value));
      },
    },
  },
});

@Component({
  components: { InlineItemsList, DropdownItemsList },
})
export default class AddToProject extends AddToProjectProps {
  get projectItems() {
    return this.projectList.map(
      (i) => ({ id: i.id, name: i.name } as ListItemModel)
    );
  }

  get checkListProjectItems() {
    return this.checkListProjects.map(
      (i) => ({ id: i.id, name: i.name } as ListItemModel)
    );
  }

  public get projectList() {
    if (this.$store.getters.getProjectsList && this.checkListProjects) {
      return this.$store.getters.getProjectsFullList.filter(
        (v) => this.checkListProjects.findIndex((t) => t.id === v.id) === -1
      );
    }

    return [];
  }

  public get checkListProjects() {
    return this.$store.getters.getCheckListsSelectedCheckList.projects
      ? this.$store.getters.getProjectsFullList.filter(
          (v) =>
            !!this.$store.getters.getCheckListsSelectedCheckList.projects.find(
              (t) => t.id === v.id
            )
        )
      : [];
  }

  get isLoading() {
    return this.$store.getters.isProjectsDataLoading;
  }

  async dropdownItemClickedHandle(item: ListItemModel) {
    const project = this.projectList.find((p) => p.id === item.id);
    if (!project) return;

    await this.projectChooseHandle(project);
  }

  async inlineItemClickedHandle(item: ListItemModel) {
    if (this.isLoading) return;
    const project = this.checkListProjects.find((p) => p.id === item.id);
    if (!project) return;

    await this.removeChosenProjectHandle(project);
  }

  async projectChooseHandle(project) {
    await this.$store.dispatch(
      StoreActionTypes.SET_PROJECTS_SELECTED_PROJECT,
      project.id
    );

    const index = project.checklists.findIndex((v) => v.id === project.id);
    if (index !== -1) {
      return console.error("project is already added");
    }

    project.checklists.push({
      id: +this.checkListId,
    });

    await this.$store.dispatch(
      StoreActionTypes.SET_PROJECTS_SELECTED_PROJECT_UPDATE_CHECKLIST,
      {
        ids: project.checklists.map((v) => v.id),
      }
    );

    this.$store.dispatch(StoreActionTypes.SET_PROJECTS_SELECTED_PROJECT_CLEAR);
    await this.$store.dispatch(
      StoreActionTypes.CHECKLISTS_SELECTED_CHECKLIST,
      this.checkListId
    );
  }

  async removeChosenProjectHandle(project) {
    await this.$store.dispatch(
      StoreActionTypes.SET_PROJECTS_SELECTED_PROJECT,
      project.id
    );

    const index = project.checklists.findIndex(
      (v) => v.id === +this.checkListId
    );
    if (index === -1) {
      return console.error("no checklist found");
    }

    project.checklists.splice(index, 1);

    await this.$store.dispatch(
      StoreActionTypes.SET_PROJECTS_SELECTED_PROJECT_UPDATE_CHECKLIST,
      {
        ids: JSON.parse(JSON.stringify(project.checklists)).map((v) => v.id),
      }
    );

    this.$store.dispatch(StoreActionTypes.SET_PROJECTS_SELECTED_PROJECT_CLEAR);
    await this.$store.dispatch(
      StoreActionTypes.CHECKLISTS_SELECTED_CHECKLIST,
      this.checkListId
    );
  }
}
