
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { Bar } from "vue-chartjs";
import { Component, Prop, Vue } from "vue-property-decorator";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

@Component({
  name: "BarChart",
  components: {
    Bar,
  },
})
export default class BarChart extends Vue {
  @Prop({ required: true }) readonly chartData: {
    labels: unknown[];
    datasets: unknown[];
  };

  chartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 1.75,
    animation: false,
    indexAxis: "y",
    layout: {
      autoPadding: false,
      padding: 0,
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    tooltips: {
      intersect: true,
    },
    scales: {
      x: {
        display: true,
        stacked: true,
      },
      y: {
        display: true,
        stacked: true,
        ticks: {
          display: true,
          z: 10,
          callback: function (val: string | number) {
            const limit = 35;
            const label: string = this.getLabelForValue(val);
            return label.length > limit
              ? `${label.substring(0, limit)}...`
              : label;
          },
        },
      },
    },
  };
}
