export const acceptableFileExtensions = [
  `.mp3`,
  `.mp4`,
  `.wav`,
  `.ogg`,
  `.m4a`,
  ".jpg",
  ".jpeg",
  ".png",
];

export const acceptableFileExtensionsString =
  acceptableFileExtensions.join(",");
