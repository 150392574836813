
import { Component, Prop, Vue } from "vue-property-decorator";
import { Line as LineChartGenerator } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement
);

@Component({
  name: "LineChart",
  components: {
    LineChartGenerator,
  },
})
export default class BarChart extends Vue {
  @Prop({ required: true }) readonly chartData: {
    labels: unknown[];
    datasets: unknown[];
  };

  chartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 2,
    animation: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    tooltips: {
      intersect: false,
      mode: "index",
    },
    scales: {
      x: {
        ticks: {
          display: false,
        },
      },
    },
  };
}
