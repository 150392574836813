<template lang="pug">
  .table-row-wrapper
    .table-row(@click="openEditDialog")
      //.col
        .checkbox
          input(type="checkbox")
      .col(style="min-width: 23%;")
        .number
          p(v-if="formattedNumber") {{ formattedNumber }}
          p.not-connected(v-else @click.stop="openCreateDialog") Привязать номер
      .col(style="min-width: 10%;")
        Status(:active="dateExpired && item.stateInstance !== 'notAuthorized'")
      .col(style="min-width: 35%;")
        .messages
          p {{ messageStatus[item.settings && item.settings.readChatSettings ? item.settings.readChatSettings.eventOnIncomingMessage : 'setReadedOnReceive'] }}
      .col(style="min-width: 10%;")
        .date
          p {{ formattedDate }}
      .col(style="width: 10%; max-width: 10%;")
          .id
            p {{ item.idInstance }}
      //.col
          .logs
            p ЛОГИ
      .col(style="width: 4%; max-width: 4%;")
        .action
          button.dots(@click.stop="() => {}" id="row-popover")
            EIcon(name="dots" height="16px" width="16px" color="#000")
          b-popover(
            triggers="click blur"
            target="row-popover"
            placement="right"
            custom-class="custom-popover"
          )
            .menu
              .menu-item(@click.stop="openQrDialog('refresh')")
                p Перепривязать номер
              .menu-item(@click.stop="openEditDialog")
                p Настроить канал
              .menu-item.delete(@click.stop="deleteInstance")
                p Удалить
</template>

<script>
import moment from "moment";
import Status from "@/components/ewa/common/Status.vue";
import ClickOutside from "vue-click-outside";
import { StoreActionTypes } from "@/store/types";
export default {
  name: "Table.Row",
  directives: {
    ClickOutside,
  },
  props: {
    item: {
      required: true,
    },
  },
  components: {
    Status,
  },
  computed: {
    formattedDate() {
      return moment(this.item.created).format("DD.MM.YYYY HH:MM");
    },
    dateExpired() {
      return moment() > moment(this.item.created);
    },
    formattedNumber() {
      return this.item.wid ? `+${this.item.wid.split("@")[0]}` : null;
    },
  },
  methods: {
    hideMenu() {
      this.showMenu = false;
    },
    openCreateDialog() {
      this.$root.openDialog({
        component: {
          is: () => import("@/components/ewa/modals/ChannelCreate.vue"),
          _name: "ChannelCreate",
          idInstance: this.item.idInstance,
        },
        settings: {
          position: "center",
        },
      });
    },
    openEditDialog() {
      this.$root.$emit("bv::hide::popover");
      this.$root.openDialog({
        component: {
          is: () => import("@/components/ewa/modals/ChannelSettings.vue"),
          _name: "ChannelSettings",
          item: {
            ...this.item,
            date: this.formattedDate,
            //mark: this.messageStatus[this.item.mark].name
          },
        },
        settings: {
          position: "right",
        },
      });
    },
    openQrDialog(type = "create") {
      this.$root.$emit("bv::hide::popover");
      this.$root.openDialog({
        component: {
          is: () => import("@/components/ewa/modals/ChannelCreate.vue"),
          _name: "ChannelCreate",
          type: type,
          idInstance: this.item.idInstance,
        },
        settings: {
          position: "center",
        },
      });
    },
    deleteInstance() {
      this.$root.$emit("bv::hide::popover");
      this.$store.dispatch(
        StoreActionTypes.DELETE_INSTANCE,
        this.item.idInstance
      );
    },
  },
  data() {
    return {
      showMenu: false,
      messageStatus: {
        setReadedOnReceive: "Помечать прочитанным при получении",
        notSetReaded: "Оставлять непрочитанным",
        setReadedOnSend: "Отмечать прочитанным при отправке",
      },
    };
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0;
}

::v-deep {
  .menu {
    background: #fff;
    padding: 6px 0;
    border-radius: 5px;
    box-shadow: 2px 2px 10px #00000024;

    .menu-item {
      width: 170px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 8px 10px;
      cursor: pointer;

      &.delete {
        background: #fff6f6;
      }

      &:hover {
        background: #00000012;
      }
    }
  }
}
.table-row-wrapper {
  .table-row {
    border-radius: 4px;
    //max-width: 1020px;
    height: 40px;
    background: #f8f9fa;
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    transition: all 0.07 ease-out;
    cursor: pointer;

    &:hover:not(.menu-item) {
      background: #e8eaeb;
    }

    .action {
      position: relative;
    }

    .col {
      display: flex;
      align-items: center;
      padding: 10px 16px;
      min-height: 40px;

      &:last-child {
        justify-content: flex-end;
      }
    }

    .number {
      //min-width: 254px;

      .not-connected {
        cursor: pointer;
        text-decoration: underline;
        color: $blue;
      }
    }

    .checkbox {
      display: flex;
      align-items: center;
      input {
        width: 12px;
        height: 12px;
      }
    }

    .messages {
      //width: 300px;
      cursor: pointer;
      text-decoration-line: underline;
      text-decoration-style: dashed;
    }
  }
}
</style>
