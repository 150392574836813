export const ebkReasonsCustomer: Record<string, string> = {
  PHONE_UNREACHABLE: "Недозвон",
  DO_NOT_MATCH_CONTRACT_CONDITIONS: "Сумма долга меньше 250 000₽",
  WRONG_PHONE_NUMBER: "Ошибка номера телефона / ФИО",
  SERVICE_IS_NOT_RELEVANT: "Не актуально",
  ANOTHER_REGION_OF_RUSSIA: "Не тот регион",
  HOSTILITY: "Негатив",
  AUXILIARY: "Залоговый кредит",
};

export const getEbkReasonsCustomerOptions = () => {
  return Object.keys(ebkReasonsCustomer).map((key) => ({
    value: key,
    text: ebkReasonsCustomer[key],
  }));
};

export function getEbkReasonCustomerLabel(key: string) {
  return ebkReasonsCustomer[key] ? ebkReasonsCustomer[key] : key;
}
