
import Vue from "vue";
import Component from "vue-class-component";
import CallReviewer from "@/components/callDetails/reviewers/CallReviewer.vue";
import { CallModel, CallEstimationModel, Profile } from "@/services/types";
import { StoreActionTypes, StoreMutationTypes } from "@/store/types";

const ICallReviewersProps = Vue.extend({
  props: {
    canRate: {
      type: Boolean,
      required: true,
    },
  },
});

@Component({
  components: {
    CallReviewer,
  },
})
export default class CallReviewers extends ICallReviewersProps {
  private componentClass = "CallReviewers";

  async handleCallReviewerClick(id: number, auditorId) {
    this.$store.commit(
      StoreMutationTypes.SET_SELECTED_CALL_SELECTED_AUDITOR_ID,
      auditorId
    );
    if (id) {
      await this.$store.dispatch(
        StoreActionTypes.SET_SELECTED_CALL_GET_ESTIMATION,
        id
      );
    } else {
      await this.$store.dispatch(
        StoreActionTypes.SET_SELECTED_CALL_CATEGORIES,
        this.getDefaultCheckListId()
      );
    }
  }

  getDefaultCheckListId(): number {
    return this.$store.getters.getSelectedCall
      ? (this.$store.getters.getSelectedCall as CallModel).checklists.find(
          (item) => item.default
        ).id
      : null;
  }

  get currentReviewer(): CallEstimationModel {
    const user = this.$store.getters.getAuthentificationProfile as Profile;
    const lastname =
      user.lastname.length > 0 ? ` ${user.lastname.split("")[0]}.` : "";

    return {
      id: null,
      value: null,
      auditor: {
        id: user.id,
        name: `${user.firstname}${lastname}`,
      },
    };
  }

  get reviewersList() {
    return this.$store.getters.getSelectedCallEstimations;
  }
}
