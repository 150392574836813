
import Vue from "vue";
import Component from "vue-class-component";
import {
  IconDefinition,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { StoreMutationTypes } from "@/store/types";

@Component
export default class CollapseArea extends Vue {
  private componentClass = "CollapseArea";
  private show = true;
  private headerIcon: IconDefinition = faChevronDown;

  get getShow() {
    return this.$store.getters.getTopCollapseOpened;
  }

  setShow() {
    this.$store.commit(
      StoreMutationTypes.SET_TOP_COLLAPSE_OPENED,
      !this.getShow
    );
  }

  get setVisible(): string {
    return this.getShow ? "visible" : "";
  }
}
